.home-banner {
   background: #121619;
   .home-bg {
      background: url(../../images/home-bg.png);
      background-position: center;
      background-size: cover;
      position: relative;
      height: fit-content;
      padding-bottom: 15em;
      overflow: hidden;
      
      .containerx{
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         align-content: center;
         justify-content: center;
         .imag-banner{
            max-width: 40em;
            margin-left: 6em;
            margin-top: 2.5em;
            
         }
         .text-box {
            color: #fff;
            margin-top: 5.5em;
            margin-left: 2em;
            a{
               text-decoration: none;
             }
            h1 {
               font-size: 5.5em;
               font-weight: 700;
               span {
                  color: #bb0000;
               }
            }
            h2{
               display: none;
               
            }
            p {
               font-size: 18px;
               letter-spacing: 5px;
               color: #bb0000;
               margin-bottom: 0;
            }
         }
      };
      .row {
         height: 100%;
      }
     
   }
}
.banner{
   margin-bottom: -50px;
   
}






@media (max-width: 1399px) {
   .home-banner {
      .home-bg {
         height: fit-content ;
         margin-bottom: 3em;
         display: flex;
         flex-direction: column;
         align-items: center;
         .containerx{
            margin-right: 0em;
            margin-left: 0em;
            justify-content: center ;
            .imag-banner{
               margin-left: 0em;
            }            
            .text-box {
               h2{
                  font-size: 3em;
                  display: block;
                  text-align: center;
                  margin: 0% 10%;
                  margin-top: 0.5em;
               }
            }
         }
      }
   }
}

@media (max-width: 700px) {
   .home-banner {
      height: 100%; 
      .home-bg {
         margin-bottom: 5em;

         .containerx{

            .imag-banner{
               max-width: 90%;
               margin-left: 2.5%;
               margin-top: 5em;
            }
            .text-box {
               margin-top: 3.8em;
               margin-left: 0;
               margin-bottom: -30px;
               h1 {
                  text-align: center;
                  font-size: 3.5em !important;
               }
               h2{
                  font-size: 2em;
                  display: block;
                  text-align: center;
                  margin: 0% 10%;
                  margin-top: 0.5em;
               }
            }
         }
      }
   }
}

@media (max-width: 350px) {
   .home-banner {
      height: 100%; 
      .home-bg {
         .containerx{
            .imag-banner{
               max-width: 90%;
               margin-left: 2.5%;
               margin-top: 5em;
            }
            .text-box {
               margin-top: 3.8em;
               margin-left: 0;
               margin-bottom: -30px;
               h1 {
                  text-align: center;
                  font-size: 2em !important;
               }
            }
         }
      }
   }
}

.white {
   color: white;
 }
 
 .red {
   color: red;
 }
