.box-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    .box-item{
         display: flex;
         align-content: center;
         justify-items: center;
         margin-bottom: 1em;
    }
    label{
        width: 100%; 
    }
    input[type="checkbox"]{
        height: 1em;
        width: 1em;
        background-color: #dfdfdf;
        border-radius: 15%;
        cursor: pointer;
        margin-right: 0.7em;
        margin-top: 0.15em;
    }
    .hide{
        display: none;
    }
    .div{
        display: flex;
        h3{
            margin-right: 0.5em;
        }

    }
    h3{
      font-size: 1em;
      font-family: "poppins";
      cursor: pointer;
    }
    h1{
        font-size: 1.3em;
        margin-bottom: 1em;
        font-family: "poppins";

    }
    .h4{ 
        margin-top: 0.3em;
        font-size: 0.9em;
        font-family: 'Courier New', monospace;
    }
}
.form-control {
        
    margin-bottom: 1em;
    border-radius: 0px;
    height: 1em;
    color: #666;
    width: 25em;
    height: 50px;
    border: 0px;
    &:focus {
       box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0.15) !important;
       border: 0px ;
    }
 }
 .btn-black {
    background: #121619;
    border: 1px solid #121619;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    margin-right: 1em;
    margin-top: -6px;
    height: 50px;
    width: 100%;
    border-radius: 13px;
    transition: 0.4s;
    margin-bottom: 2em;
    &:hover {
       background: transparent;
       color: #121619;
    }
 }  
@media (max-width: 768px) {
   
    .box-container{
        label{
            width: 100%;
        }
        h2{
            width: 100%;

        }
        h1{            
            width: 100%;}
        .box-item{    
            width: 100%;}

        margin-left: 0.5em;
        width: 100%;
    }
    .form-control {
        
        width: 100%;
     }

}