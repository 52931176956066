.footer-section {
   padding: 175px 0 25px 0;
   position: relative;
   background: #121619;
   color: #fff;
   &::before {
      content: '';
      width: 100%;
      height: 125px;
      background: url(../../images/black-top-layer.png);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -1px;
      left: 0;
      z-index: 1;
   }
   .logo {
      font-family: 'Pacifico', cursive;
      color: #fff;
      font-size: 32px;
      font-weight: normal !important;
      margin-bottom: 30px; 
      span {
         color: #fe9d3e;
      }
   }
   .footer-box {
      h5 {
         margin-bottom: 31px;
         margin-top: 16px;
         span {
            color: #bb0000;
         }
      }

      .nav-item {
         margin-bottom: 15px;
         color: #999;
         .link {
            color: #999;
            transition: .4s;
            &:hover {
               color: #fe9d3e;
            }
         }
         span {
            text-align: right;
            float: right;
         }
         .closed {
            background: #fe9d3e;
            color: #fff;
            padding: 0 10px;
         }
      }
      p {
         color: #999;
      }
   }
   .hour-box {
      max-width: 300px;

      .nav-item {   
         text-align: left;
      }
   }
   .copyright {
      color: #999;
      border-top: 1px solid #222;
      padding-top: 35px;
      padding-bottom: 15px;
      margin-top: 50px;
   }
}

@media (max-width: 991px) {
   .footer-section {
      text-align: center;
      .footer-box {
         p{
            text-align: start;
            margin-left: 10%;
            margin-right: 10%;
         }
         li{
            text-align: start;
            margin: 0% 10%;
         }
      }   
      .hour-box {
         margin: auto;
         br{
            display: none;
         }
      }
   }
   
}