.navbar {
   background: transparent;
   padding: 14px 0 !important;
   transition: .3s;
   display: flex;
   justify-content: center;
   align-content: center;
  
      .logo-img{
         width: 4.5em;
      }
      .nav-item {
         height: fit-content;
         justify-content: center;
         .red {
            font-size: 1.5em;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            margin-right:  1em;
            transition: .4s;
         }
         .white{
            font-size: 1.5em;
            color: #ff0000;
            text-decoration: none;
            text-transform: uppercase;
            margin-right:  1em;
            transition: .4s;
         }
      
         .btn-orange {
            height: 45px;
            width: 125px;
         }
      }
   
   
     


}

@media (max-width: 1399px) {
   .navbar {
      display: flex;         
      .logo-img{  
         width: 3.5em;
         margin-right: 30em;
      }
      .container {
         .nav-item{  
            a{
               display: none;
            }
         }
      }
   } 
}  




@media (max-width: 767px) {
   .navbar {
      width: 100%;
      .container {
         display: flex;
         .logo-img{  
            width: 3.5em;
            margin-right: 30em;
         }
      }
   } 
}

@media (max-width: 700px) {
   .navbar {
      width: 100%;
      .container {
         display: flex;
         .nav-item{  
            a{
               display: none;
            }
         }
      }
   } 
}
@media (max-width: 550px) {    
   
      .navbar { 
         .nav-item{
            margin-right: 1em;
         }
         .logo-img{  
            width: 3.5em;
            margin-left: 1em;
         }
      } 
   
}
@media (max-width: 350px) {
   .navbar {
      .nav-item{
         margin-right: 0.2em;
      }
      .logo-img{  
         width: 3.5em;
         .nav-item{
            margin-right: 0.2em;
         }
      }
   } 
}