.item{
    
    text-decoration: none;
    color: black;
    &:hover{
        color: rgb(0, 0, 0);
        width: 25%;
        margin:0%;
    }
    
    width: 23%;
    margin:1%;
    .logo-img{
        width: 100%;
        margin-bottom: 1em;
        border: 1px solid #121619;  
     }
    .disc{
        margin-left: 0.7em;
        width: 95%;

    
    h3{
        font-family: 'Poppins';
        font-size: 1.5em;
    }
    h4{
        font-size: 1em;
        color: rgb(79, 79, 79);
        margin-left: 0%;
        width: 90%;

    }
    h5{
        font-size: 1.2em;
    }
}}

@media (max-width: 768px) {
    .item{
        width: 46%;
        margin: 2%;
        &:hover{
            width: 50%;
        }
        .logo-img{   
            display: flex;
            width: 100%;
            margin-bottom: 1em;

         }
        .disc{
            width: 90%;
            margin-left: 2%;
        
        h3{
            font-size: 1.4em;
        }
        h4{
            font-size: 0.9em;

        }
        h5{
            font-size: 1.1em;
        }
    }
    }
}
