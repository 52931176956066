.dad{

    position: sticky;
    top: 0;
    background: rgba( #000000,  0.7);
    margin-bottom: 3em;

    .bar{
        margin: 0 9%;
        padding-top: 1em;
        padding-bottom: 1em;
        .nav{   
            cursor: grab;
            overflow: hidden;
            color: #fff;
            .inner{
                display: flex;
                .button{
                    background: transparent;
                    border: transparent;
                    display: block;
                    color: white;
                    margin-right: 3em;
                    padding: 0%;
                    h4{                   
                        font-size: 1.3em;
                        margin-left: 0em;
                        margin-bottom: 0em;
                        width: max-content;
                }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .dad{
        .bar{
            .nav{
                .inner{
                    .button{
                        h4{
                            font-size: 1.1em;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 500px) {
    .dad{
        .bar{
            .nav{
                .inner{
                    .button{
                        h4{
                            font-size: 0.9em;
                        }
                    }
                }
            }
        }
    }
}
.nav-back{
    opacity: 0;
 }