.feature-section {
   padding: 50px 0;
   margin-bottom: 5em;
   background: #ff0000;
   position: relative;
   display: flex;
   &::before {
      content: '';
      width: 100%;
      height: 125px;
      background: url(../../images/feature-top-layer.png);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -124px;
      left: 0;
      z-index: 1;
   }
   &::after {  
      content: '';
      width: 100%;
      height: 125px;
      background: url(../../images/feature-bottom-layer.png);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      bottom: -124px;
      left: 0;
      z-index: 1;
   }
   .cont{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;      
      .feature-box {
         text-align: center;
         margin-left: 8em;
         margin-right: 8em;
         .icon {
            margin-bottom: 30px;
            img {
               width: 100px;
               height: 100px;
            }
         }
      }
   
   }
   
   
   
}
@media (max-width: 1399px) {

   .feature-section {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      margin-bottom: 0em   ;
      margin-top: -5em;
      height: 15em;
      &::before {
         top: -4.3em;
         height: 6em;
      }
      &::after {
         bottom: -4.3em;
         height: 6em;}   
      .cont{
         height: auto;
         
         .feature-box {
            width: 20%;
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 0em;
            .icon {
               margin-bottom: 1em;
               .image{
                  width: 100%;
               }
            }
            h4{
               font-size: 1.1em;
               font-weight: 600;
            }
         }
      }
   }

}
@media (max-width: 768px) {
   

   .feature-section {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      margin-top: -12em;
      margin-bottom: 0em   ;
      height: 10em;
      &::before {
         top: -4.3em;
         height: 5em;

      }
      &::after {
         bottom: -4.3em;
         height: 5em;}   
      .cont{
         height: auto;
         
         .feature-box {
            width: 20%;
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 0em;
            .icon {
               margin-bottom: 1em;
               .image{
                  width: 100%;
               }
            }
            h4{
               font-size: 0.8em;
               font-weight: 600;
            }
         }
      }
   }
}
@media (max-width: 414px) {
   .feature-section {
      .cont{
         .feature-box {
            width: 20%;
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 0em;
            .icon {
               margin-bottom: 1em;
               .image{
                  margin-top: -1.5em;
                  width: 100%;
                  padding: 0 10%;
                  
               }
            }
            h4{
               margin-top: -1.5em;
               font-size: 0.65em;
               font-weight: 600;
            }
         }
      }
   }
}