.hams{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 3em;
    .box{
        width: 90%;
        align-items: center;
        align-self: center;
        h1{
            font-size: 3em;
            margin-left: 4%;
            margin-bottom: 0.5em;
        }
        .h4spec{
            width: 80%;
            margin-left: 5%;
            margin-top: -0.5em;
            margin-bottom: 1.3em;
        }
        .container{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
        
        }
    
    
}
}

@media (max-width: 768px) {
    .hams{
        .box{
            width: 100%;
            h1{
                margin-left: 5%;
            }
            .h4spec{
                margin-top: -1em;
                font-size: small;
                width: 90%;
            }
            .container{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
            }
        }   
    }
}
@media (max-width: 300px) {
    .hams{
        .box{
            width: 100%;
            h1{
                font-size: 2em;
                margin-left: 5%;
            }
            .h4spec{
                margin-top: -1em;
            }
        }   
    }
}
